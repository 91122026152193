<template>
  <v-container id="calendar-view" fluid tag="section">
    <view-intro heading=""> </view-intro>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>August 2019</v-toolbar-title>

            <v-spacer />

            <calendar-btn v-for="(t, i) in types" :key="i" @click="type = t">
              {{ t }}
            </calendar-btn>

            <v-spacer />

            <v-btn icon @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-btn icon @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>

            <v-btn icon @click="add()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-sheet height="400" elevation="0" class="pa-3">
            <v-calendar
              ref="calendar"
              v-model="calendar"
              :events="events"
              class="elevation-0"
              :type="type"
              event-color="primary"
              now="2019-01-08"
            />
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Components
import AppBtn from "@/components/app/Btn"

export default {
  name: "CalendarView",

  components: {
    CalendarBtn: {
      extends: AppBtn,

      props: {
        icon: {
          type: Boolean,
          default: true
        },
        color: {
          type: String,
          default: "secondary"
        },
        minWidth: {
          type: Number,
          default: 0
        },
        rounded: {
          type: Boolean,
          default: true
        }
      },

      computed: {
        classes() {
          return {
            ...AppBtn.computed.classes.call(this),
            "elevation-0 mx-1 text-lowercase px-4": true
          }
        }
      }
    }
  },
  methods: {
    add() {
      this.$router.push({ name: "FormWorkingTime", params: {} })
    }
  },
  data: () => ({
    calendar: "2019-01-08",
    disabled: true,
    events: [
      {
        name: "Vacation",
        start: "2019-01-08 09:15:00",
        end: "2019-01-09 12:00:00"
      }
      // {
      //   name: 'Meeting',
      //   start: '2019-01-07',
      // },
      // {
      //   name: '30th Birthday',
      //   start: '2019-01-03',
      // },
      // {
      //   name: 'New Year',
      //   start: '2019-01-01',
      // },
      // {
      //   name: 'Conference',
      //   start: '2019-01-21',
      // },
      // {
      //   name: 'Hackathon',
      //   start: '2019-01-30',
      //   end: '2019-02-01',
      // },
    ],
    focus: "",
    type: "month",
    types: ["month", "week", "day"]
  })
}
</script>

<style lang="sass">
#calendar
  .v-calendar-weekly__day:last-child,
  .v-calendar-weekly__head-weekday:last-child
    border-right: none

  .v-calendar-weekly__week:last-child .v-calendar-weekly__day
    border-bottom: none
</style>
